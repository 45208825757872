import {
  Flex,
  HStack,
  Text,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Link,
  useDisclosure,
} from '@chakra-ui/react';
import { useSession, signOut } from 'next-auth/react';
import { useRouter } from 'next/router';

export default function UserProfile() {
  const router = useRouter();
  const { data: session } = useSession();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const focusMenu = () => {
    return ['/login', '/plus-movil/games', '/game', '/account/infos'].includes(
      router.pathname
    );
  };

  return (
    <HStack zIndex={1}>
      {!session && (
        <Link
          color={focusMenu() ? '#FA571B' : 'black'}
          fontWeight={focusMenu() ? 'bold' : 'normal'}
          onClick={() => {
            router.push('/plus-movil/games');
          }}
        >
          Mi Desafío
        </Link>
      )}
      {session && (
        <Flex alignItems="center">
          <Menu isOpen={isOpen}>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: 'none' }}
              color={focusMenu() ? '#FA571B' : 'black'}
              fontWeight={focusMenu() ? 'bold' : 'normal'}
              onMouseOver={() => onOpen()}
              onMouseLeave={() => setTimeout(() => onClose(), 5000)}
              onClick={() => {
                onClose();
                router.push('/plus-movil/games');
              }}
            >
              Mi Desafío
            </MenuButton>
            <MenuList color="#1D1D1D" bg="white">
              <MenuItem
                onClick={() => {
                  onClose();
                  router.push('/plus-movil/games');
                }}
                
              >
                <Text fontFamily={'mainfont'} color="#1D1D1D" size={'md'}>
                  Mi Desafío
                </Text>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onClose();
                  router.push('/account/infos');
                }}
              >
                <Text fontFamily={'mainfont'} color="#1D1D1D" size={'md'}>
                  Mi Info
                </Text>
              </MenuItem>
              <MenuDivider />
              <MenuItem
                color="#9D1D96"
                fontWeight={'bold'}
                onClick={() => {
                  onClose();
                  signOut();
                }}
              >
                Salir
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      )}
    </HStack>
  );
}
