import { useEffect } from 'react';
import { useRouter } from 'next/router';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Text,
} from '@chakra-ui/react';

import NavLink from '@components/NavLinkPlusMovil';
import { VscBook } from 'react-icons/vsc';
import { BsFillInfoSquareFill, BsQuestionCircle } from 'react-icons/bs';
import { IoDocumentOutline } from 'react-icons/io5';
import { IoIosMedal, IoMdTrophy } from 'react-icons/io';
import { FaQuestion } from 'react-icons/fa';
import {  MdGames } from 'react-icons/md';
import { signOut, useSession } from 'next-auth/react';

const LinkItems = [
  { label: 'Home', icon: VscBook, href: '/' },
  {
    label: 'Cómo Participar',
    icon: BsQuestionCircle,
    href: '/plus-movil/participate',
  },
  { label: 'Premios', icon: IoIosMedal, href: '/plus-movil/prizes' },
  { label: 'Ganadores', icon: IoMdTrophy, href: '/plus-movil/winners' },
  { label: 'Bases', icon: IoDocumentOutline, href: '/bases' },
  { label: 'Preguntas Frecuentes', icon: FaQuestion, href: '/faq' },
  {
    label: 'Ganadores Previos',
    icon: IoMdTrophy,
    href: '/plus-movil/winners-previous',
  },
  // { label: "Desafío Previo", icon: MdGames, href: "/plus-movil/games" },
];

export default function Sidebar({
  onClose,
  isOpen,
  ...rest
}: {
  onClose: any;
  isOpen: boolean;
  rest?: any;
}) {
  const router = useRouter();

  const { status } = useSession();

  useEffect(() => {
    router.events.on('routeChangeComplete', onClose);
    return () => {
      router.events.off('routeChangeComplete', onClose);
    };
  }, [router.events, onClose]);

  return (
    <Drawer
      autoFocus={false}
      isOpen={isOpen}
      placement="left"
      onClose={onClose}
      returnFocusOnClose={false}
      onOverlayClick={onClose}
      // size="full"
    >
      <DrawerOverlay bgColor={'rgb(250, 87, 27, 0.65)'} />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader color="black" fontSize="2xl">
          {process.env.NEXT_PUBLIC_APP_NAME}
        </DrawerHeader>

        <DrawerBody>
          <Box fontFamily="TradeGothicLTStd-Cn18" {...rest}>
            {/* <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Text color="black" fontSize="2xl">
          {process.env.NEXT_PUBLIC_APP_NAME}
        </Text>
        <CloseButton
          color="black"
          display={{ base: 'flex', md: 'none' }}
          onClick={onClose}
        />
      </Flex> */}
            {LinkItems.map((link, i) => (
              <NavLink key={i} link={link} />
            ))}
            {status != 'authenticated' ? (
              <NavLink
                link={{
                  label: 'Mi Desafio',
                  icon: MdGames,
                  href: '/plus-movil/games',
                }}
              />
            ) : (
              <Accordion defaultIndex={[0]}  allowMultiple >
                <AccordionItem border="none">
                  <AccordionButton p={0}>
                    <AccordionIcon color={"#363636"} />
                    <Box
                      color={'#363636'}
                      flex="1"
                      textAlign="center"
                      fontSize={'lg'}
                    >
                      Mi Desafío
                    </Box>
                  </AccordionButton>
                  <AccordionPanel p={1}>
                    <NavLink
                      link={{
                        label: 'Mi Desafío',
                        icon: MdGames,
                        href: '/plus-movil/games',
                      }}
                    />
                    <NavLink
                      link={{
                        label: 'Mi Info',
                        icon: BsFillInfoSquareFill,
                        href: '/account/infos',
                      }}
                    />
                    <Flex
                      align="center"
                      p="1"
                      // mx="2"
                      borderRadius="lg"
                      role="group"
                      cursor="pointer"
                      _hover={{
                        bg: '#FF4713',
                      }}
                      onClick={() => {
                        signOut();
                      }}
                    >
                      <Text lineHeight={0} color="black" fontSize="1.2rem">
                        Salir
                      </Text>
                    </Flex>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            )}
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
