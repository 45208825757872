import {
  Container,
  Box,
  Card,
  CardBody,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
} from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';

export default function Footer({ minH }: { minH?: string }) {
  // const router = useRouter();
  const { t } = useTranslation('landing-page');
  return (
    <Container
      fontFamily="TradeGothicLTStd-Cn18"
      centerContent
      minH={minH}
      color={'black'}
    >
      <Card>
        <CardBody>
          <Accordion minWidth={'90vw'} defaultIndex={[0]} allowMultiple>
            <AccordionItem border={'none'}>
              <AccordionButton>
                <AccordionIcon />
                <Box
                  color={'#363636'}
                  flex="1"
                  textAlign="center"
                  fontSize={'lg'}
                >
                  Términos de la promoción
                </Box>
              </AccordionButton>
              <AccordionPanel pb={4} textAlign={'center'}>
                <Text
                  color={'#363636'}
                  lineHeight={1.2}
                  fontSize={'md'}
                  fontFamily={'TradeGothicLTStd-Cn18'}
                  dangerouslySetInnerHTML={{
                    __html: t('banner-footer-description'),
                  }}
                />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </CardBody>
      </Card>
    </Container>
  );
}
