import { Flex, HStack, Link, Icon } from '@chakra-ui/react';
import { MdMenu } from 'react-icons/md';
import { useRouter } from 'next/router';
import Image from 'next/image';
// import { Pages, usePages } from '@context/pagesContext';
import UserProfile from '@components/UserProfilePlusMovil';

export default function Header({
  onOpen,
  minH,
  ...rest
}: {
  onOpen: any;
  minH: string;
  rest?: any;
}) {
  const router = useRouter();
  // const { page, changePage } = usePages();

  const links = [
    {
      path: '/',
      title: 'Home',
      // page: Pages.Home,
    },
    {
      path: '/plus-movil/participate',
      title: 'Cómo Participar',
      // page: Pages.Participate,
    },
    {
      path: '/plus-movil/prizes',
      title: 'Premios',
      // page: Pages.Prizes,
    },
    {
      path: '/plus-movil/winners',
      title: 'Ganadores',
      // page: Pages.Winners,
    },
    {
      path: '/bases',
      title: 'Bases',
      // page: Pages.Terms,
    },
    {
      path: '/faq',
      title: 'Preguntas Frecuentes',
      // page: Pages.FAQ,
    },
    {
      path: '/plus-movil/winners-previous',
      title: 'Ganadores Previos',
      // page: Pages.Winners,
    },
    // {
    //   path: '/plus-movil/games',
    //   title: 'Desafío Previo',
    //   // page: Pages.Games,
    // },
  ];
  return (
    <Flex
      fontFamily="TradeGothicLTStd-Cn18"
      position="sticky"
      top="0"
      p={'0 0.5rem'}
      margin={'0 auto'}
      height={'4.5rem'}
      zIndex="1"
      boxShadow={'0px 1px 10px grey'}
      bgColor={'white'}
      alignItems="center"
      justifyContent={'center'}
      minH={minH}
      {...rest}
    >
      <HStack
        display={{ base: 'flex', md: 'none' }}
        justify={'space-between'}
        w={'full'}
      >
        <Icon
          onClick={onOpen}
          color="black"
          w={6}
          h={6}
          size="lg"
          aria-label="open menu"
          as={MdMenu}
          zIndex={1}
        />
        <Image
          alt="Logo"
          height={'38px'}
          width={'38px'}
          src={'/imgs/plus_movil/logo.png'}
        />
      </HStack>

      <HStack
        zIndex={1}
        spacing={{
          base: '20px',
          sm: '20px', // 480px
          md: '10px', // 768px
          lg: '30px', // 992px
          xl: '56px', // 1280px
          '2xl': '56px', // 1536px
        }}
        display={{ base: 'none', md: 'flex' }}
        mr={{ base: 0, lg: '14vw' }}
      >
        <Link onClick={() => {
              router.push("/");
            }}>
          <Image
            alt="Logo"
            height={'38px'}
            width={'38px'}
            src={'/imgs/plus_movil/logo.png'}
          />
        </Link>
        {links.map((link) => (
          <Link
            key={link.path}
            color={router.pathname == link.path ? '#FA571B' : 'black'}
            fontWeight={router.pathname == link.path ? 'bold' : 'normal'}
            onClick={() => {
              router.push(link.path);
              // changePage(link.page);
            }}
          >
            {link.title}
          </Link>
        ))}
        <UserProfile />
      </HStack>
    </Flex>
  );
}
