import Header from '@components/HeaderPlusMovil';
import Sidebar from '@components/SidebarPlusMovil';
import Footer from '@components/Footer';
import { Box, useDisclosure } from '@chakra-ui/react';
import { useTheme } from '@hooks/useTheme';
import { useEffect } from 'react';
import { ThemeNames } from '@context/themeContext';

export default function Layout({
  children,
}: // background,
{
  children: any;
  background?: string;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { setTheme } = useTheme();

  useEffect(() => {
    setTheme(ThemeNames.default);
  }, []);
  return (
    <Box minH="100vh">
      {/* <Sidebar
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      /> */}

      <Sidebar onClose={onClose} isOpen={isOpen} />
      <Header onOpen={onOpen} minH="7vh" />
      <Box minH="69vh">{children}</Box>
      <Footer minH="12vh" />
    </Box>
  );
}
